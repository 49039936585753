<template>
  <div>
    <Header />
    <div id="banner" class="owl-carousel owl-theme index__banner">
      <div class="item" v-for="a in IndexBanner" :key="a.id">
        <img  :src="'/tw/upload/' + a.image" alt="" />
        <div class="index__banner__title">
          <h1 v-if="a.title" v-html="a.title"></h1>
          <h4 v-if="a.title1" v-html="a.title1"></h4>
        </div>
      </div>
    </div>

    <div class="index__about">
      <div class="container">
        <div class="index__about__title">
          <h2 v-html="IndexBlock1.title"></h2>
        </div>
        <div class="index__about__content">
          <h4 v-html="IndexBlock1.descript"></h4>
        </div>
        <div class="index__about__btn">
          <router-link :to="{name: 'About'}"><h5>更多內容</h5></router-link>
        </div>
      </div>
    </div>
    <div class="index__product" :style="{backgroundImage: 'url(' + baseUrl + IndexBlock2.image +')'}">
      <div class="container">
        <div class="index__product__title">
          <h2 v-html="IndexBlock2.title"></h2>
        </div>
        <div class="index__product__content">
          <h4 v-html="IndexBlock2.descript"></h4>
        </div>
      </div>
      <div class="container__product__list">
        <div class="index__product__list">
          <router-link :to="{ name: 'product', params: {ptid: 174, psid: 177, pdid: 180}}">
            <div class="item">
              <div class="index__product__list__img">
                <img
                  :src="'/tw/upload/' + IndexBlock2_1.image"
                  alt=""
                />
              </div>
              <div class="index__product__list__title">
                <h4 class="product-title" v-html="IndexBlock2_1.title"></h4>
                <!-- <h4 class="small" v-html="IndexBlock2_1.title1"></h4> -->
              </div>
            </div>
          </router-link>
          <router-link :to="{ name: 'product', params: {ptid: 175, psid: 243, pdid: 247}}">
            <div class="item item_reverse">
              <div class="index__product__list__img">
                <img
                  :src="'/tw/upload/' + IndexBlock2_2.image"
                  alt=""
                />
              </div>
              <div class="index__product__list__title">
                <h4 class="product-title" v-html="IndexBlock2_2.title"></h4>
                <h4 class="small" v-html="IndexBlock2_2.title1"></h4>
              </div>
            </div>
          </router-link>
          <router-link :to="{ name: 'product', params: {ptid: 176, psid: 275}}">
            <div class="item">
              <div class="index__product__list__img">
                <img
                  :src="'/tw/upload/' + IndexBlock2_3.image"
                  alt=""
                />
              </div>
              <div class="index__product__list__title">
                <h4 class="product-title" v-html="IndexBlock2_3.title"></h4>
                <h4 class="small" v-html="IndexBlock2_3.title1"></h4>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="index__video">
      <video
        controls="controls"
        src="../assets/pic/video.mp4"
        poster="../assets/pic/menu__banner.png"
        width="100%"
      ></video>
      <div class="index__video__text">
        <div class="index__video__text_wrapper">
          <div class="index__video__title">
            <h2 v-html="IndexBlock3.title"></h2>
          </div>
          <div class="index__video__content">
            <h4   v-html="IndexBlock3.descript">
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="index__service">
      <div class="index__service__img">
        <img
          style="width: 100%"
          :src="'/tw/upload/' + IndexBlock4.image"
          alt=""
        />
      </div>
      <div class="index__service__block">
        <div class="index__service__title"> 
          <h2 v-html="IndexBlock4.title"></h2>
        </div>
        <div class="index__service__content">
          <h4 v-html="IndexBlock4.descript"></h4>
        </div>
        <div class="index__service__btn">
          <a :href="IndexBlock4.url">更多內容</a>
        </div>
      </div>
    </div>
    <div class="index__invest">
      <div class="index__invest__content">
        <div class="index__invest__block index__invest__block__top">
          <div class="index__invest__block__img">
            <a :href="IndexBlock5_1.url"
              ><img
                :src="'/tw/upload/' + IndexBlock5_1.image"
                alt=""
            /></a>
          </div>
          <a
            class="index__invest__block__text"
            :href="IndexBlock5_1.url"
            v-html="IndexBlock5_1.title"
          >
          </a>
        </div>

        <div class="index__invest__block index__invest__block__bottom">
          <div class="index__invest__block__img">
            <a :href="IndexBlock5_2.url"
              ><img
                :src="'/tw/upload/' + IndexBlock5_2.image"
                alt=""
            /></a>
          </div>
          <a
            class="index__invest__block__text"
            :href="IndexBlock5_2.url"
            v-html="IndexBlock5_2.title"
          >
          </a>
        </div>

        <div class="index__invest__block index__invest__block__top">
          <div class="index__invest__block__img">
            <a :href="IndexBlock5_3.url"
              ><img
                :src="'/tw/upload/' + IndexBlock5_3.image"
                alt=""
            /></a>
          </div>
          <a
            class="index__invest__block__text"
            :href="IndexBlock5_3.url"
            v-html="IndexBlock5_3.title"
          >
          </a>
        </div>

        <div class="index__invest__block index__invest__block__bottom">
          <div class="index__invest__block__img">
            <a :href="IndexBlock5_4.url"
              ><img
                :src="'/tw/upload/' + IndexBlock5_4.image"
                alt=""
            /></a>
          </div>
          <a
            class="index__invest__block__text"
            :href="IndexBlock5_4.url"
            v-html="IndexBlock5_4.title"
          >
          </a>
        </div>

        <div class="index__invest__block index__invest__block__top">
          <div class="index__invest__block__img">
            <a :href="IndexBlock5_5.url">
              <img
                :src="'/tw/upload/' + IndexBlock5_5.image"
                alt=""
            /></a>
          </div>
          <a
            :href="IndexBlock5_5.url"
            class="index__invest__block__text"
            v-html="IndexBlock5_5.title"
          >
          </a>
        </div>
      </div>
    </div>
    <div class="index__other">
      <div class="container">
        <div class="index__other__block">
          <router-link
            class="index__other__block__title"
            :to="{name:'Resource'}"
            v-scroll-to="'#Resource'"
            v-html="IndexBlock6_1.title"
          >
          </router-link>
          <div class="index__other__block__icon">
            <router-link :to="{name:'Resource'}" v-scroll-to="'#Resource'">
              <img src="../assets/pic/other__icon1.png" alt=""
            /></router-link>
          </div>
          <div class="index__other__block__img">
            <router-link :to="{name:'Resource'}" v-scroll-to="'#Resource'">
              <img
                style="width: 100%"
                :src="'/tw/upload/' + IndexBlock6_1.image"
                alt=""
            /></router-link>
          </div>
        </div>
        <div class="index__other__block">
          <router-link
            class="index__other__block__title"
            :to="{name:'Contact'}"
            v-scroll-to="'#Contact'"
            v-html="IndexBlock6_2.title"
          >
          </router-link>
          <div class="index__other__block__icon">
            <router-link :to="{name:'Contact'}"
            v-scroll-to="'#Contact'"
              ><img src="../assets/pic/other__icon2.png" alt=""
            /></router-link>
          </div>
          <div class="index__other__block__img">
            <router-link :to="{name:'Contact'}"
            v-scroll-to="'#Contact'"
              ><img
                style="width: 100%"
                :src="'/tw/upload/' + IndexBlock6_2.image"
                alt=""
            /></router-link>
          </div>
        </div>
        <div class="index__other__block">
          <router-link :to="{name:'Report'}"
          v-scroll-to="'#Report'" 
            class="index__other__block__title"
            v-html="IndexBlock6_3.title"
          >
          </router-link>
          <div class="index__other__block__icon">
            <router-link :to="{name:'Report'}"
            v-scroll-to="'#Report'"
              ><img src="../assets/pic/other__icon3.png" alt=""
            /></router-link>
          </div>
          <div class="index__other__block__img">
            <router-link :to="{name:'Report'}"
            v-scroll-to="'#Report'"
              ><img
                style="width: 100%"
                :src="'/tw/upload/' + IndexBlock6_3.image"
                alt=""
            /></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./../components/Header";
import "./../assets/js/owl.carousel";
import "./../assets/js/main.js";
import { apiHome } from "@/api/pages.js";

export default {
  name: "Home",
  data() {
    return {
      IndexBanner: [],
      IndexBlock1: {},
      IndexBlock2: {},
      IndexBlock2_1: {},
      IndexBlock2_2: {},
      IndexBlock2_3: {},
      IndexBlock3: {},
      IndexBlock4: {},
      IndexBlock5: {},
      IndexBlock5_1: {},
      IndexBlock5_2: {},
      IndexBlock5_3: {},
      IndexBlock5_4: {},
      IndexBlock5_5: {},
      IndexBlock6_1: {},
      IndexBlock6_2: {},
      IndexBlock6_3: {},
      baseUrl: '/tw/upload/'
    };
  },
  components: {
    Header,
  },
    // p<meta property="og:url" content="https://www.elevator.com.tw/tw#/" />
    // <meta property="og:locale" content="zh_TW" />
    // <meta property="og:type" content="website" />
    // <meta property="og:description" content="相對於小縮圖而言，大縮圖能顯示的文字量是比較少的，所以圖片上的資訊更加重要" />
    // <meta property="og:title" content="大縮圖分享連結" />
    // <meta property="og:image:type" content="image/png" />
    // <meta property="og:image"
		// content="https://github.com/dean9703111/open_graph_sample/blob/master/img/big_thumbnail.png?raw=true" />
    // <meta property="og:image:width" content="1400" />
    // <meta property="og:image:height" content="770" />
  metaInfo: {
    meta:[
      {
        property: 'og:url',
        content: 'https://www.elevator.com.tw/tw#/'
      },
      {
        property: 'og:locale',
        content: 'zh_TW'
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:description',
        content: 'HOME相對於小縮圖而言，大縮圖能顯示的文字量是比較少的，所以圖片上的資訊更加重要'
      },
      {
        property: 'og:title',
        content: '大縮圖分享連結HOME',
        vmid: 'og:title'
      },
      {
        property: 'og:image:type',
        content: 'image/png'
      },
      {
        property: 'og:image',
        content: 'https://github.com/dean9703111/open_graph_sample/blob/master/img/big_thumbnail.png?raw=true'
      },
      {
        property: 'og:image:width',
        content: '1400'
      },
      {
        property: 'og:image:height',
        content: '770'
      },
    ]
  },
  mounted: async function () {
    const res = await apiHome();
    console.log(res)
    this.IndexBanner = res.data.IndexBanner;
    this.IndexBlock1 = res.data.IndexBlock1;
    this.IndexBlock2 = res.data.IndexBlock2;
    this.IndexBlock2_1 = res.data.IndexBlock2_1;
    this.IndexBlock2_2 = res.data.IndexBlock2_2;
    this.IndexBlock2_3 = res.data.IndexBlock2_3;
    this.IndexBlock3 = res.data.IndexBlock3;
    this.IndexBlock4 = res.data.IndexBlock4;
    this.IndexBlock5 = res.data.IndexBlock5;
    this.IndexBlock5_1 = res.data.IndexBlock5_1;
    this.IndexBlock5_2 = res.data.IndexBlock5_2;
    this.IndexBlock5_3 = res.data.IndexBlock5_3;
    this.IndexBlock5_4 = res.data.IndexBlock5_4;
    this.IndexBlock5_5 = res.data.IndexBlock5_5;
    this.IndexBlock6_1 = res.data.IndexBlock6_1;
    this.IndexBlock6_2 = res.data.IndexBlock6_2;
    this.IndexBlock6_3 = res.data.IndexBlock6_3;

    setTimeout(() => {
      $(".index__banner").owlCarousel({
        loop: true,
        margin: 0,
        autoHeight: true,
        autoplay: true,
        autoplayTimeout: 4000,
        autoplayHoverPause: true,
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 1,
          },
          800: {
            items: 1,
          },
          1000: {
            items: 1,
          },
        },
      });
    }, 1000);
    $(function () {
      $("#gotop").on("click", function () {
        $("html,body").animate({ scrollTop: 0 }, "slow");
        return false;
      });
      $(window).scroll(function () {
        if ($(this).scrollTop() > 400) {
          $("#gotop").fadeIn();
        } else {
          $("#gotop").fadeOut();
        }
      });
    });
  },
};
</script>

<style lang="css">
.owl-carousel .owl-item {
  width: 100%;
}

.index__other__block__title,
.index__invest__block a,
.index__service__title,
.index__video__title.container,
.index__product__list__title h4,
.index__product__title h2,
.index__about__title {
  font-weight: 700;
}
@media screen and (min-width: 576px) {
  .index__video__text .container {
    max-width: auto !important;
  }
}
</style>
